import '/src/sass/app.scss';

import Alpine from 'alpinejs';
//import focus from '@alpinejs/focus';
//import collapse from '@alpinejs/collapse';

window.Alpine = Alpine;
//Alpine.plugin(focus);
//Alpine.plugin(collapse);
Alpine.start();

// Import Swiper and its modules
import Swiper from 'swiper';
import { Pagination, Navigation, EffectFade, Autoplay } from 'swiper/modules';

// Select all slider elements on the page
var swiperElements = document.querySelectorAll(".swiper");

// Iterate over each slider element and initialize a Swiper instance
swiperElements.forEach(function (swiperElement) {
    var swiper = new Swiper(swiperElement, {
        modules: [Pagination, Navigation, EffectFade, Autoplay],
        pagination: {
            el: swiperElement.querySelector(".slider-pagination"),
            clickable: true,
        },
        navigation: {
            nextEl: swiperElement.querySelector('.slider-button-next'),
            prevEl: swiperElement.querySelector('.slider-button-prev'),
        },
        speed: 1000,
        autoplay: {
            delay: 5000,
        },
        effect: "fade",
        fadeEffect: {
            crossFade: true
        },
        on: {
            slideChangeTransitionStart: function () {
                // Slide captions
                var swiper = this;
                var slideTitle = swiper.slides[swiper.activeIndex].getAttribute("data-title");
                var slideCaptions = swiperElement.querySelector(".slide-captions");
                slideCaptions.innerHTML = `<p>${slideTitle}</p>`;
            }
        }
    });

    // Set initial caption on load
    var initialTitle = swiper.slides[swiper.activeIndex].getAttribute("data-title");
    swiperElement.querySelector(".slide-captions").innerHTML = `<p>${initialTitle}</p>`;
});
